import React, { useState } from "react";
import { blogs } from "./Data";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async'

function Blogpost() {

    const { id } = useParams();
    const blog = blogs.find(blog => blog.id === id);

    const [isVisible, setIsVisible] = useState(false);

    window.onscroll = () => {
        setIsVisible(window.pageYOffset > 300);
    };


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (<React.Fragment>
        <div className="flex w-full flex-col mt-20 py-3 selection:bg-orange-200 ">
        <Helmet>
        <title>Couriero - Exploring the future of logistics</title>
        <meta name="description" content="Discover insights and trends in the logistics industry with Couriero. Our blog post explores the future of logistics, innovative solutions, and trends shaping parcel delivery services." />
        <link rel="canonical" href={"https://couriero.in/blogs${"} />
      </Helmet>
            <div className="lg:p-8 px-2 lg:px-24  lg:justify-center lg:items-center text-justify">
                <div className="p-6 px-6 flex flex-col text-justify ">
                    {blog.content.map((block, index) => {
                        switch (block.type) {
                            case 'Main-Title':
                                return <h2 key={index} className="lg:text-2xl lg:font-medium font-semibold text-base  mb-9 text-center">{block.text}</h2>;
                            case 'title':
                                return <h2 key={index} className="text-xl font-medium my-3">{block.text}</h2>;
                            case 'paragraph':
                                return <p key={index} className="text-lg mb-4">{block.text}</p>;
                            case 'image':
                                return <img key={index} src={block.src} alt={block.alt} className="mb-4 shadow-xl w-full rounded-lg" />;
                            default:
                                return null;
                        }
                    })}
                </div>
                <div className="flex justify-center">
                    <button
                        className={`fixed bottom-10 right-10 bg-slate-300 font-bold py-2 px-5 rounded-lg transition-opacity duration-700   ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                        onClick={scrollToTop}
                    >
                        Scroll to Top
                    </button>
                </div>
            </div>
            <div className="mt-8 border selection:bg-blue-100 bg-slate-300 mx-2  lg:mx-24">
                <div className="flex flex-col py-5  px-2 lg:px-8">
                    <h2 className="text-2xl font-semibold  mb-4 text-slate-800">Leave a Reply</h2>
                    <h2 className="text-sm font-semibold mb-2 ">Your email address will not be published. Required fields are marked *</h2>
                    <form>
                        <div className="mb-4 mt-2 w-full justify-center items-start">
                            <label className="pt-2 font-bold text-slate-800 text-lg">Comment*</label>
                            <textarea id="comment" rows="4" className="mt-1 flex pl-3 pt-1 text-sm focus:outline-none  w-full bg-white border-gray-300   shadow-md " />
                        </div>
                        <div className="flex w-full lg:space-x-5 flex-col lg:flex-row">
                            <div className="flex flex-col lg:w-2/6">
                                <h3 className="pt-2 text-slate-800 font-bold text-lg">Name*</h3>
                                <input className="placeholder:pl-2 bg-white mt-1 mb-3 shadow-md focus:outline-none pl-2 text-sm py-1 " />
                            </div>
                            <div className="flex flex-col lg:w-2/6">
                                <h3 className="pt-2 text-slate-800 font-bold text-lg">Email*</h3>
                                <input className="placeholder:pl-2 bg-white mt-1 mb-3 shadow-md focus:outline-none pl-2 text-sm py-1 " />
                            </div>
                            <div className="flex flex-col lg:w-2/6">
                                <h3 className="pt-2 text-slate-800 font-bold text-lg">Website*</h3>
                                <input className="placeholder:pl-2 bg-white mt-1 mb-3 shadow-md focus:outline-none pl-2 text-sm py-1 " />
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="items-center flex px-4 mt-2 py-2 rounded-3xl font-bold shadow-lg text-base text-white bg-blue-500 hover:bg-blue-700 focus:outline-none">
                                Post Comment
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>)
}
export default Blogpost;