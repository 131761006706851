import React from "react"
import Phone from "../Assets/Contact-us/call-us.png"
import Email from "../Assets/Contact-us/email-us.png"
import location from "../Assets/Contact-us/map.png"
import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'

function Contactus() {
    const emailAddress = 'support@couriero.in';

    const [open1, setopen1] = useState('')
    const [open2, setopen2] = useState('')
    const [open3, setopen3] = useState('')
    const [open4, setopen4] = useState('')
    const [open5, setopen5] = useState('')

    const toogleQuestion1 = () => {
        setopen1(!open1)
        setopen2(false)
        setopen3(false)
        setopen4(false)
        setopen5(false)
    }
    const toogleQuestion2 = () => {
        setopen2(!open2)
        setopen1(false)
        setopen3(false)
        setopen4(false)
        setopen5(false)
    }
    const toogleQuestion3 = () => {
        setopen3(!open3)
        setopen2(false)
        setopen1(false)
        setopen4(false)
        setopen5(false)
    }
    const toogleQuestion4 = () => {
        setopen4(!open4)
        setopen3(false)
        setopen2(false)
        setopen1(false)
        setopen5(false)
    }
    const toogleQuestion5 = () => {
        setopen5(!open5)
        setopen4(false)
        setopen3(false)
        setopen2(false)
        setopen1(false)
    }

    return (
        <React.Fragment>
            <div className="flex flex-col  mt-20 justify-center items-center">
            <Helmet>
        <title>Couriero - Contact Us </title>
        <meta name="description" content="Get in touch with Our Team. Contact us for any inquiries, support, or assistance regarding delivery services and courier tracking." />
        <link rel="canonical" href="https://couriero.in/contact-us" />
      </Helmet>
                <div className="flex border  flex-col gap-3 pb-8 pt-4   w-full  justify-center items-center">
                    <div className="flex lg:flex-row justify-center items-center flex-col gap-1">
                         <h3 className="text-4xl font-bold  text-blue-900">We're Listening!</h3>
                        <h3 className="lg:text-3xl text-xl pt-1 lg:font-semibold text-slate-800">Tell us What's in your Mind!</h3>
                    </div>
                    <h3 className="font-semibold lg:text-lg text-slate-800 text-base">We are ready to serve you the best support</h3>
                </div>
                <div className="flex 2xl:px-60 lg:flex-row flex-col pt-4 lg:pb-20 pb-10 w-full ">
                    <div className="flex lg:w-4/6">
                        <iframe className="lg:h-full flex h-80 w-full lg:m-8 mx-5 my-3 rounded-md lg:rounded-xl" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.12258097394!2d75.81574347401694!3d26.86784626201986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db7b51669dee9%3A0x3708a6ae96453829!2sBhamashah%20Techno%20Hub!5e0!3m2!1sen!2sin!4v1718184047259!5m2!1sen!2sin" allowfullscreen="" loading="lazy" ></iframe>
                    </div>
                    <div className="flex lg:pt-16 pt-5 justify-center lg:px-0 px-4     flex-col  lg:w-2/6">
                        <div className="flex flex-col">
                            <h3 className="text-lg text-orange-600 font-bold">Need Support?</h3>
                            <h3 className="text-blue-900 font-semibold">Contact us if you need further assistance.</h3>
                        </div>
                        <div className="flex border-b py-6 flex-col">
                            <div className="flex items-center flex-row">
                                {/* <i className="fa-solid fa-envelope text-blue-900 text-xl pr-5 "></i> */}
                                <img src={Email} className="flex w-14 h-9  pr-5" alt="email" />
                                <h3 className="text-lg font-semibold">Write to us</h3>
                            </div>
                            <a href={`mailto:${emailAddress}`} className="pl-14 font-medium hover:underline">support@couriero.in</a>
                        </div>
                        <div className="flex border-b py-6 flex-col">
                            <div className="flex items-center flex-row">
                                {/* <i className="fa-solid fa-phone  text-blue-900 text-xl "></i> */}
                                <img src={Phone} className="flex w-14 h-8  pr-5" alt="phone" />
                                <h3 className="text-lg font-semibold ">Call us on</h3>
                            </div>
                            <h3 className="pl-14 font-medium cursor-pointer">+91-7976810979</h3>
                        </div>

                        <div className="flex border-b py-6 flex-col">
                            <div className="flex items-center flex-row">
                                {/* <i className="fa-solid fa-location-dot  text-blue-900 text-xl pr-5 "></i> */}
                                <img src={location} className="flex w-14 h-8  pr-5" alt="location" />
                                <h3 className="text-lg font-semibold ">Visit us</h3>
                            </div>
                            <h3 className="pl-14 font-medium">6th Floor, Bhamashah Techno Hub, Sansthan Marg,</h3>
                            <h3 className="pl-14 font-medium"> Malviya Nagar, Jaipur 302017</h3>
                        </div>

                    </div>
                </div>
                <div className='flex 2xl:px-60 2xl:pb-20 justify-center lg:my-4 px-4 lg:px-36 flex-col w-full h-full'>
                    <h3 className='text-3xl mb-4 text-slate-800 underline-offset-8 underline font-bold  text-center '>
                         FAQs
                    </h3>
                    <div className='flex  flex-col space-y-3 w-full mt-4'>
                        <div className='flex flex-col items-center shadow-md pl-2 w-full'>
                            <div onClick={toogleQuestion1} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    1.  When will my order be delivered?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1  py-1 rounded-full ${open1 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open1 &&
                                <div className=' w-full'>
                                    <p className='pb-3 text-justify mb-2 pr-4 font-medium text-base'> Your order will be delivered on the estimated date of delivery shown on the tracking information.</p>
                                </div>}
                        </div>
                        <div className='flex flex-col shadow-md items-center pl-2 w-full'>
                            <div onClick={toogleQuestion2} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    2. What are Couriero's Business Hours?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open2 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open2 &&
                                <div className=' w-full'>
                                    <p className='pb-3 text-justify mb-2 pr-4 font-medium text-base '> You can give us text or call in between 10:00 am to 6:00 pm for any concern.</p>
                                </div>}
                        </div>
                        <div className='flex flex-col shadow-md items-center pl-2 w-full'>
                            <div onClick={toogleQuestion3} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg  w-11/12 text-slate-800 font-semibold mb-4">
                                    3. How do I File a Complaint or Provide  Feedback?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open3 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open3 &&
                                <div className=' w-full'>
                                    <p className='pb-3 mb-2 font-medium pr-4 text-base'>You can mail your concern anytime at Support@couriero.in .</p>
                                </div>}
                        </div>
                        <div className='flex shadow-md flex-col items-center pl-2 w-full'>
                            <div onClick={toogleQuestion4} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    4.   What if my Question is not listed here?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open4 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open4 &&
                                <div className=' w-full'>
                                    <p className='text-justify pr-4 mb-2 font-medium text-base'>
                                    You can view your tracking related query here: <Link to='track-shipment'>TrackingPage</Link>
                                    </p>
                                    <p className="pb-3 text-justify pr-4 mb-2 font-medium text-base">Also you can mail your query if not listed in FAQs at Support@couriero.in</p>
                                </div>}
                        </div>
                        <div className='flex flex-col shadow-md items-center pl-2 w-full'>
                            <div onClick={toogleQuestion5} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    5.  My package shows as "Delivered," but I haven't received it. What should I do?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open5 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open5 &&
                                <div className='w-full '>
                                    <p className='pb-3 mb-2 text-justify pr-4 items-center font-medium text-base '>Write us your requirement at Support@couriero.in, for International/Global shipping.</p>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
}

export default Contactus;