import React from 'react'

const RefundPolicy = () => {
    return (
        <React.Fragment>
            <div className='pt-32 text-slate-700 md:px-28 px-8'>
                <h1 className='text-center font-bold text-3xl mb-20'>Refund And Cancellation Policy</h1>
                <p className='text-sm font-semibold tracking-wide my-1'>Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.</p>
                <p className='text-sm font-semibold tracking-wide my-1'>In case of dissatisfaction from our services, clients have the liberty to cancel their projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:</p>
                <h2 className='my-10 text-lg font-bold capitalize'> Cancellation Policy</h2>
                <p className='text-sm font-semibold tracking-wide my-1'>For Cancellations please contact the us via contact us link. </p>
                <p className='text-sm font-semibold tracking-wide my-1'>Requests received later than 7 business days prior to the end of the current service period will be treated as cancellation of services for the next service period.</p>
                <h2 className='my-10 text-lg font-bold capitalize'>Refund Policy</h2>
                <p className='text-sm font-semibold tracking-wide my-1'>We will try our best to create the suitable design concepts for our clients.</p>
                <p className='text-sm font-semibold tracking-wide my-1'>In case any client is not completely satisfied with our products we can provide a refund. </p>
                <p className='text-sm font-semibold tracking-wide my-1'>If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account.</p>
            
            
            </div>
        </React.Fragment>
    )
}

export default RefundPolicy
