import React from "react";
import banner from "../Assets/Aboutus.png";
import { Helmet } from 'react-helmet-async'

function Aboutus() {


  return (<React.Fragment>
    <div className="flex flex-col w-full text-slate-700 justify-center items-center text-justify   mt-20">
    <Helmet>
        <title>Couriero - About us </title>
        <meta name="description" content="Learn about Couriero's journey and our commitment to transforming the logistics industry. Discover our focus on integrity, innovation, and customer satisfaction in providing top-notch courier services." />
        <link rel="canonical" href="https://couriero.in/about-us" />
      </Helmet>
      <div className="flex 2xl:px-52  flex-col lg:w-3/4">
        <h3 className="flex  font-bold text-xl lg:text-4xl  justify-center font-sans">Welcome to Couriero</h3>
        <h3 className="flex font-medium text-lg lg:text-center px-5 pt-3 pb-2">At Couriero, our journey began with a bold vision: to transform the logistics industry. From humble beginnings, we've emerged as a trusted name in courier services, driven by our passion for innovation and commitment to excellence.</h3>
        <h3 className="flex font-medium text-lg px-5 lg:text-center">With a focus on integrity and customer satisfaction, we're pioneering new standards in transportation, shaping the future of logistics with every delivery.</h3>
      </div>
      <div className="pt-16 hidden 2xl:hidden  lg:flex">
        <img alt="banner" className="w-full px-5 brightness-75 " src={banner}></img>
      </div>
      <div className="relative   mx-8 lg:bg-white 2xl:mt-20  2xl:bg-orange-300 2xl:bg-opacity-40 2xl:mb-14  lg:bg-opacity-70 bg-orange-200  mt-5 lg:mt-[-35rem] lg:mb-32  lg:text-blue-900 shadow-md lg:w-1/2 " >
        <div className="flex  p-6 lg:px-12  flex-col">
          <h3 className="flex lg:text-3xl text-xl pt-4 font-bold justify-center">About Us</h3>
          <h3 className="flex lg:text-xl text-lg pt-2 font-bold  justify-center">We’re on a mission to make things Simple</h3>
          <h3 className="flex lg:text-lg pb-3 lg:pt-6 pt-2 font-medium text-justify justify-center">Since our inception, we've remained dedicated to providing reliable and efficient courier services to businesses and individuals alike. With a team of skilled professionals and a culture of continuous improvement, we strive to exceed our customers' expectations at every turn.</h3>
          <h3 className="flex lg:text-lg  lg:py-3  font-medium text-justify justify-center">Whether it's delivering parcels across town or managing complex logistics operations, we approach every task with dedication, precision, and a relentless commitment to customer satisfaction.</h3>
          <h3 className="flex lg:text-lg text-sm pt-2 font-bold justify-left">A Message from,</h3>
          <h3 className="flex lg:text-lg pb-4 text-sm font-bold justify-left">Founder</h3>
        </div>
      </div >
      <div className="flex  lg:space-x-3 flex-col lg:flex-row w-full">
        <div className="flex flex-col   items-center  mb-4 mt-12  lg:w-2/6">
          <div className="flex lg:pt-10 flex-col lg:w-1/2 lg:px-2 justify-center items-center">
            <i className="fas fa-money-bill-alt text-green-500 text-lg"></i>
            <h3 className="flex font-bold">COD Services</h3>
            <h3 className="lg:text-sm w-3/4 font-medium text-center">Securely pay for your order upon delivery with our COD service.</h3>
          </div>
          <div className="lg:pt-28 pt-10 flex flex-col lg:w-1/2 items-center">
            <i className="fa-solid fa-headset text-blue-500 text-xl"></i>
            <h3 className="flex text-lg font-bold">Customer Support</h3>
            <h3 className="lg:text-sm w-3/4 font-medium text-center">Need help? Reach out to our friendly customer support</h3>
          </div>
        </div>
        <div className="flex flex-col lg:bg-orange-400 lg:text-slate-800 bg-orange-200 mx-8 shadow-md   mb-4 mt-12   lg:w-2/6">
          <div className="flex flex-col p-6">
            <h3 className="flex text-2xl  font-bold justify-center">Our Mission</h3>
            <h3 className="flex  py-3  font-medium lg:text-center justify-center">Our mission is to redefine the logistics industry by offering innovative solutions that meet the evolving needs of our customers.</h3>
            <h3 className="flex  lg:py-3 pb-3  font-medium lg:text-center justify-center">We aim to build lasting relationships based on trust, integrity, and transparency, while continuously improving our services to exceed expectations.</h3>
            <h3 className="flex  font-medium lg:text-center justify-center">  Experience the difference with Couriero and join thousands of satisfied customers who trust us with their shipping needs. Contact us today to learn more about our services or to request a quote.</h3>
          </div>
        </div>
        <div className="flex flex-col   items-center  mb-4 mt-12  lg:w-2/6">
          <div className="flex lg:pt-10 flex-col lg:w-1/2 lg:px-2 justify-center items-center">
            <i className="fas fa-coins text-yellow-500 text-lg"></i>
            <h3 className="flex font-bold">Lowest Price</h3>
            <h3 className="lg:text-sm w-3/4 font-medium text-center">Securely pay for your order upon delivery with our COD service.</h3>
          </div>
          <div className="lg:pt-28 pt-10 flex flex-col lg:w-1/2 px-3 justify-center items-center">
            <i className="fas fa-check-circle text-gray-500  text-lg"></i>
            <h3 className="flex font-bold">Reduce RTO</h3>
            <h3 className="lg:text-sm w-3/4 font-medium text-center">Cut Down RTO Times: Optimize Your Delivery Process Today</h3>
          </div>
        </div>
      </div>
      <div className="flex 2xl:mt-20 flex-col py-2 px-5  mb-10 mt-7 border rounded-lg shadow-lg mx-4  lg:w-3/4 ">
        <h3 className="flex justify-center py-2 lg:text-2xl text-xl font-sans font-bold">Our Team</h3>
        <h3 className="flex justify-center pb-2 lg:text-base text-sm lg:text-center font-sans font-medium">Behind every successful delivery is a team of dedicated professionals who work tirelessly to ensure the smooth operation of our logistics services. From our drivers and warehouse staff to our customer service representatives and management team, each member plays a vital role in our company's success.</h3>
      </div>
      <div className='flex flex-col   lg:flex-row  justify-center items-center bg-gradient-to-r from-purple-50 to-blue-300 w-full py-4 lg:py-16 lg:px-8'>
        <div className='flex flex-col px-4 justify-start text-center w-full lg:w-1/2'>
          <h3 className='flex w-full lg:w-3/4 cursor-pointer text-2xl lg:text-4xl  lg:pb-2 font-extrabold'>Subscribe to</h3>
          <h3 className='flex w-full cursor-pointer text-2xl lg:text-4xl  font-extrabold'>Couriero Newschannel</h3>
        </div>
        <div className='flex flex-row px-4 items-center my-2 justify-center w-full lg:w-1/2  '>
          <input className='w-3/4 outline-none pl-4    border border-gray-400 shadow-lg py-1  lg:h-10 lg:text-xl text-sm rounded-lg' placeholder='Enter your e-mail address' />
          <button className='lg:px-4 py-1 px-2 border border-gray-400 mx-4 w-1/4  bg-blue-500 shadow-lg  text-white rounded-lg text-sm lg:text-2xl font-sans'>Subscribe</button>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default Aboutus;