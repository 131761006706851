import React from "react";
import Bluedart from "../Assets/SeoRoutes/Bluedart.png"
import Shippingpartners from "../Assets/SeoRoutes/mapdesign2.png"
import Dtdc from "../Assets/SeoRoutes/Dtdc.png"
import Delhivery from "../Assets/SeoRoutes/delhivery.png"
import Xpressbeas from "../Assets/SeoRoutes/xpressbeas.png"
import ecomexpress from "../Assets/SeoRoutes/ecom-express.png"
import SideBannerGrey1 from "../Assets/SeoRoutes/SideBannerGrey1.png"
import amazon from "../Assets/SeoRoutes/amazon.png"
import { Link , useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";


function SeoRoutestwo() {

    const location = useLocation();
    const path = location.pathname;

    let title ;
    let description;

    switch (path) {
        case "/courier-services-near-me":
            title = "Best Courier Services Near You";
            description = "Find the best courier services available near you through Couriero. Compare rates and discover reliable delivery options tailored to your needs.";
            break;
        case "/lowest-shipping-rates":
            title = "Lowest Shipping Rates Available";
            description = "Explore the lowest shipping rates offered by top courier services on Couriero. Get the best deals for your shipping needs.";
            break;
        case "/top-courier-services":
            title = "Top Courier Services in India";
            description = "Discover the top courier services in India with Couriero. Compare and choose the best delivery options for your requirements.";
            break;
        case "/best-delivery-services":
            title = "Best Delivery Services for Your Needs";
            description = "Explore the best delivery services available on Couriero. Get reliable and efficient solutions for all your shipping needs.";
            break;
        default:
            title = "Best Courier Services";
            description = "Find the best courier services available through Couriero.";
    }

    const blogs = [
        {
            id: 1,
            imageUrl: Delhivery,
            title: 'Delhivery',
            content: "Delhivery provides fast courier services and dependable parcel delivery service, ensuring your shipments reach their destination on time through a vast network of locations. As one of the best courier services, Delhivery caters to both personal and business needs with tailored solutions.",

            contenttwo: "Their courier pickup from home adds convenience, while their same day courier service is ideal for urgent deliveries. Known as one of the most reliable courier delivery companies, Delhivery offers express courier service with innovative logistics and seamless tracking, making them the go-to choice for efficient courier delivery across India.",
            point: "Delhivery offers",
            Subpoint1: "Extensive Network",
            subpoint2: "Advanced Technology",
            subpoint3: "Customer Focus"
        },
        {
            id: 2,
            imageUrl: Dtdc,
            title: 'Dtdc',
            content: "DTDC stands out with its extensive domestic courier services and worldwide shipping options, earning its place as one of the best courier companies. Known for punctuality and safety, DTDC ensures that your packages arrive on time and in excellent condition.",
            contenttwo: "Whether you're looking for cheap parcel delivery or a same day courier service, DTDC offers flexible solutions, including courier pickup from home and comprehensive logistics management. With a commitment to quality, DTDC remains one of the top choices for cheap courier services and parcel delivery near me.",
            point: "DTDC offers",
            Subpoint1: "International Shipping",
            subpoint2: "Diverse Services",
            subpoint3: "Quality Assurance"
        },
        {
            id: 3,
            imageUrl: Bluedart,
            title: 'Blue Dart',
            content: "Blue Dart leads the industry in express courier services, offering reliable parcel courier services both domestically and internationally. Known for their punctuality and secure deliveries, Blue Dart ensures your shipments are handled with care.",
            contenttwo: "If you're searching for same day delivery courier near me or cheap delivery services, Blue Dart offers a variety of options, including courier pickup and one day delivery courier. Their dedication to high-quality service makes them one of the best parcel delivery services in India.",
            point: "Blue Dart offers",
            Subpoint1: "Reliable Service",
            subpoint2: "Advanced Tracking",
            subpoint3: "National and International Reach",
        }
        ,
        {
            id: 4,
            imageUrl: Xpressbeas,
            title: 'XpressBeas',
            content: "Xpressbees is a leading name in the courier delivery service industry, offering reliable and efficient same day parcel delivery across India and internationally. Their services, including courier pickup from home and same day courier service, ensure that your packages reach their destination quickly and safely.",
            contenttwo: "Known for their comprehensive logistics management, Xpressbees is the perfect choice for anyone looking for fast courier services near me. As one of the best courier services in India, they are committed to meeting various shipping needs with high-quality service.",
            point: "XpressBeas offers",
            Subpoint1: "E-Commerce Focus",  
            subpoint2: "Swift Delivery",
            subpoint3: "Excellent Service",
        },
        {
            id: 5,
            imageUrl: ecomexpress,
            title: 'Ecom Express',
            content: "Ecom Express stands out for its fast courier services and same day parcel delivery, catering to e-commerce businesses with a focus on reliability and speed. With their one day courier service, Ecom Express ensures that your packages are delivered promptly.",
            contenttwo: "Their courier pickup from home option and real-time tracking make shipping seamless for both individuals and businesses. As one of the leading courier companies near me, Ecom Express guarantees efficient, customer-centric service for all your delivery needs.",
            point: "Ecom Express offers",
            Subpoint1: "E-Commerce Specialization",
            subpoint2: "Timely Deliveries",
            subpoint3: "Real-Time Tracking",
        }
        ,
        {
            id: 6,
            imageUrl: amazon,
            title: 'Amazon Shipping',
            content: "Amazon Shipping offers fast courier services tailored to the needs of e-commerce businesses, leveraging Amazon’s vast infrastructure for reliable courier delivery service. Their courier pickup from home and real-time tracking features make them a trusted choice for cheap courier services.",

            contenttwo: "Whether you need same day delivery courier or a reliable parcel service near me, Amazon Shipping stands out as one of the best courier companies for e-commerce, offering dependable and efficient solutions.",
            point: "Amazon Shipping offers",
            Subpoint1: "Efficient Delivery",
            subpoint2: "User-Friendly Tracking",
            subpoint3: "Customer Support",
            date: 'April 2, 2024',
            author: 'Bhuvan Ralhan'
        }
    ]
    


    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={path} />  {/* Canonical tag */}
            </Helmet>
            <div className="flex mt-20 flex-col ">
                <div className="flex 2xl:px-32 bg-gradient-to-r from-purple-50 to-blue-200    flex-row">
                    <div className="flex py-10 gap-3 md:w-1/2 lg:w-1/2 flex-col px-10">
                        <h1 className="flex pl-1 text-slate-600 text-clip text-lg">Searching for Best Courier Platform?</h1>
                        <h2 className="lg:text-[3.6rem] md:text-[3.6rem] text-[2.2rem] leading-[2.6rem] font-semibold tracking-tighter lg:leading-[4rem] md:leading-[4rem] bg-gradient-to-r bg-clip-text from-blue-300 to-orange-300 text-transparent">Top courier and parcel <span className="font-semibold text-indigo-900">
                        Service</span>
                        </h2>
                        <h2 className=" text-sm text-justify pt-2">Avoid the hassle of searching for the best courier service ! With Couriero, you can depend on our domestic courier services to fulfill all your shipping needs. Whether you require courier pickup from home or are looking for fast courier services near me, we offer a comprehensive list of the top courier delivery service providers in your area. </h2>
                        <div>
                            <Link to="/">
                                <button class="relative lg:text-base md:text-base text-sm  mt-4 pr-11 pl-7 hover:shadow-md  lg:py-3 py-2  hover:duration-300  bg-blue-900  hover:text-blue-900 hover:border hover:border-blue-900 font-semibold hover:bg-white  text-white lg:rounded-xl md:rounded-xl rounded-r-full rounded-tl-full  group">
                                    Sign up for free
                                    <i class="fa-solid fa-arrow-right absolute pl-2 pt-1 transition-transform duration-300 ease-in-out group-hover:translate-x-1"></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="lg:flex 2xl:pl-44 md:flex hidden w-1/2 ">
                        <div className="flex justify-center items-center">
                            <img alt="Shipping partner banner" className="w-full mb-9 max-h-96  max-w-xl" src={Shippingpartners} />
                        </div>
                    </div>
                </div>
                <div className="flex 2xl:px-60 flex-row mb-10 px-7 w-full">
                    <div className='lg:grid   w-full lg:mx-5 md:mx-5  h-full  lg:grid-flow-row grid-cols-2 gap-4  lg:w-8/12'> 
                        {blogs.map(blog => (
                            <div key={blog.id} className='flex rounded-xl mt-4  px-5 py-8 pb-0 border hover:duration-200 hover:shadow-lg  justify-between shadow-gray-400 flex-col ml-2 '>

                                <img src={blog.imageUrl}  alt='blog' className='w-24 ml-1 flex' />

                                <div className='flex px-1  flex-col '>
                                    <h3 className='lg:text-base cursor-pointer hover:underline text-justify lg:text-left font-semibold py-4'>{blog.title}</h3>
                                    <p className='lg:text-[0.8rem] text-gray-600   text-sm mt-1 text-justify lg:text-start'>{blog.content}</p>
                                    <p className='lg:text-[0.8rem] text-gray-600 text-sm mt-1 text-justify lg:text-start'>{blog.contenttwo}</p>
                                </div>
                                <div className='flex px-1 text-sm pt-5 mb-8 pb-2 flex-row w-full justify-start'>
                                    <Link to="/">
                                        <button className="flex px-7 hover:bg-white hover:text-blue-900 hover:border-blue-900 hover:border border-white hover:font-semibold border py-1 bg-blue-900 text-white  rounded-tr-full rounded-full">Try Now</button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="lg:flex md:flex hidden flex-col w-4/12">
                        <div className='flex px-5 pt-4 '>
                            <img className='shadow-xl rounded-xl' alt='couriero-banner' src={SideBannerGrey1}></img>
                        </div>
                    </div>
                </div>
                <div className="flex 2xl:px-60 flex-col w-full ">
                    <h3 className="flex px-12 text-gray-600 lg:text-justify  text-justify md:text-left text-sm pt-3 pb-10">
                    Couriero excels with features designed to enhance your parcel delivery service. Enjoy top-notch customer support that’s always available to help, along with real-time tracking updates to keep you informed at every step. Our user-friendly shipping rates calculator lets you easily estimate costs before sending your package. With partnerships across more than 15 delivery courier companies, Couriero ensures access to a reliable network offering fast courier services. Whether you need courier pickup from home or a same day courier service, Couriero provides a seamless courier delivery service tailored to meet your needs.</h3>
                </div>
                <div className="flex 2xl:px-40 lg:flex-row flex-col bg-gradient-to-r rounded-3xl mb-11 py-14 from-white via-blue-200 to-blue-300 ">
                    <div className="flex px-10 lg:w-1/2 md:w-1/2 w-full flex-col">
                        <h3 className="flex  font-bold lg:w-3/4 lg:text-left md:text-left text-center md:leading-[3rem] lg:leading-[3rem] bg-clip-text bg-gradient-to-r from-blue-600 to-purple-700 text-transparent text-3xl md:text-[3rem]  lg:text-[3.1rem]">
                            Get started with Couriero
                        </h3>
                        <h3 className="text-base flex justify-center items-center lg:justify-start  lg:items-start
                        md:justify-start md:items-start pt-2 pl-1 font-semibold text-indigo-900">We ensure your shipping is fast, reliable, and hassle-free, providing a seamless delivery experience every time.</h3>
                    </div>
                    <div className="flex lg:w-1/2   justify-end items-end">

                        <div className='flex  lg:mr-20 md:mr-20 mt-5  mb-3 flex-row  w-full text-center justify-center md:justify-end lg:justify-end items-center'>
                            <a href='http://app.couriero.in/login' target='_blank' rel="noopener noreferrer">
                                <button className='flex py-3 text-lg px-10 bg-blue-800 text-white font-bold rounded-3xl shadow-md rounded-bl-none hover:border duration-300  hover:font-extrabold hover:border-blue-800 hover:bg-white hover:text-blue-900'>
                                    Sign-up now
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SeoRoutestwo;