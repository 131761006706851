import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import courieroLogo from '../Assets/couriero-logo.png'

const Navbar = () => {
  const [xmarkVisibility, setXmarkVisibility] = useState('hidden')
  const [barsVisibility, setBarsVisibility] = useState('block')
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showmenu, setshowmenu] = useState(false);




  const toggle = () => {
    if (barsVisibility === 'block') {
      setBarsVisibility('hidden')
      setXmarkVisibility('block')
    } else {
      setBarsVisibility('block')
      setXmarkVisibility('hidden')
    }
  }

  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };


  const Menu = () => {
    setshowmenu(!showmenu);
  }


  return (
    <div className='fixed inset-x-0 top-0 left-0 py-2 bg-white bg-opacity-40 backdrop-blur-sm   z-50 '>
      <div className='flex flex-row justify-around my-2 items-center'>
        <div className='flex flex-row items-center'>
          <div className='lg:hidden mx-2'>
            <i className={`fa-solid fa-bars ${barsVisibility}`} onClick={toggle}></i>
            <i className={`fa-solid fa-xmark ${xmarkVisibility}`} onClick={toggle}></i>
            <div className={`${xmarkVisibility} absolute top-16 left-5 rounded-2xl w-56 bg-gradient-to-r from-orange-200 to-blue-500`} >
              <ul className='flex flex-col justify-between bg-white m-1 rounded-2xl'>
                <Link to='/'>
                  <li className='mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500'>HOME <span className='absolute right-5'><i className="fa-solid fa-arrow-right"></i></span></li>
                </Link>
                <li onClick={Menu} className='mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500'>PRODUCT <span className='absolute right-5'><i className="fa-solid fa-arrow-down animate-bounce"></i></span>
                  {showmenu && <ul>
                    <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                      <div className=' items-center    pt-4 text-sm flex flex-row hover:bg-gray-100'>
                        <li className="  text-gray-600 text-xs w-11/12 cursor-pointer  transition duration-300">E-commerce shipping</li>
                        <i class="fa-solid w-1/12  fa-arrow-right"></i>
                      </div>
                    </a>
                    <Link to='/track-shipment'>
                    <div className='items-center w-full    pt-4 text-sm flex flex-row hover:bg-gray-100'>
                      <li className=" text-gray-600 w-11/12  text-xs cursor-pointer transition duration-300">Track Shipment</li>
                      <i class="fa-solid  1/12 fa-arrow-right"></i>
                    </div>
                    </Link>
                    <Link to="/shipping-rate-calculator">
                      <div className='pr-2 pt-3 items-center text-sm flex w-full flex-row hover:bg-gray-100'>
                        <li className=" text-gray-600 cursor-pointer text-xs transition w-11/12 duration-300">Shipping rate calculator</li>
                        <i class="fa-solid px-2  w-1/12 fa-arrow-right"></i>
                      </div>
                    </Link>
                  </ul>
                  }
                </li>
                <Link to='/features'>
                  <li className='mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500'>FEATURES <span className='absolute right-5'><i className="fa-solid fa-arrow-right"></i></span></li>
                </Link>
                <Link to='/blogs'>
                  <li className='mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500'>BLOGS <span className='absolute right-5'><i className="fa-solid fa-arrow-right"></i></span></li>
                </Link>
                <Link to='/track-shipment'>
                  <li className='mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider'>TRACK SHIPMENT <span className='absolute right-5'><i className="fa-solid fa-arrow-right"></i></span></li>
                </Link>
              </ul>
            </div>
          </div>
          <div className='mx-2'>
            <Link to='/'>
              <img src={courieroLogo} className='lg:h-9 blur-none lg:w-48 h-7 w-36' alt='courieroLogo' />
            </Link>
          </div>
        </div>
        <div className='hidden lg:block mx-1'>
          <ul className='flex flex-row justify-between'>
            <Link to='/'>
              <li className='mx-3 px-2 text-gray-600  hover:text-blue-900   font-bold text-sm tracking-wider'>HOME</li>
            </Link>
            <li className='mx-3 px-2 cursor-pointer hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider' onMouseEnter={toggleSubmenu}
              onMouseLeave={toggleSubmenu}> PRODUCTS
              {showSubmenu && (
                <ul className=" absolute py-2 bg-white    flex   flex-col   border border-gray-300 rounded-lg ">
                  <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                    <div className='px-2  items-center border-b  py-3 text-sm flex flex-row hover:bg-gray-100'>
                      <i class="fa-solid px-2 text-blue-600 fa-truck-fast"></i>
                      <li className="  text-gray-600  cursor-pointer transition duration-300">E-commerce shipping</li>
                    </div>
                  </a>
                  <Link to='/track-shipment'>
                  <div className='px-2 py-3 border-b   items-center text-sm flex flex-row hover:bg-gray-100'>
                    <i class="fa-solid text-blue-600 px-2 fa-map-location"></i>
                    <li className=" text-gray-600   cursor-pointer transition duration-300">Track Shipment</li>
                  </div>
                  </Link>
                  <Link to="/shipping-rate-calculator">
                    <div className='px-2 py-3 items-center text-sm flex flex-row hover:bg-gray-100'>
                      <i class="fa-solid px-2 text-blue-600 fa-calculator"></i>
                      <li className=" text-gray-600 cursor-pointer transition duration-300">Shipping rate calculator</li>
                    </div>
                  </Link>
                </ul>
              )}
            </li>
            <Link to='/features'>
              <li className='mx-3 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>FEATURES</li>
            </Link>
            <Link to='/blogs'>
              <li className='mx-3 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>BLOGS</li>
            </Link>
            <Link to='/track-shipment'>
              <li className='mx-3 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>TRACK SHIPMENT</li>
            </Link>
          </ul>
        </div>
        <div className='flex flex-row items-center mx-2'>
          <a href='http://app.couriero.in/login' target='_blank' rel="noopener noreferrer">
            <button className='mr-2 rounded-full bg-white shadow-2xl py-1 px-5 text-blue-900 text-base font-bold border border-blue-900 duration-300 hover:text-white hover:bg-blue-900 hover:shadow-lg hover:border-white '>Login</button>
          </a>
          <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
            <button className='ml-2 rounded-full bg-blue-900 shadow-2xl py-1 px-5 text-white text-base font-bold lg:block hover:text-blue-900 duration-300 hover:shadow-lg hover:bg-white hover:border hover:border-blue-800 hidden'>Get Started for Free</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Navbar
