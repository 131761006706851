import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// import delhiveryLogo from '../Assets/CompanyLogos/delhiverylogo.png'


const TrackShipmentDetails = () => {


    const params = useParams()
    const paramTrackAwb = params.trackAwb

    const [orderDetails, setOrderDetails] = useState(null)


    useEffect(() => {
        trackAwbDetails(paramTrackAwb)
    }, [paramTrackAwb])


    const trackAwbDetails = async (paramTrackAwb) => {
        const response = await fetch('https://api.couriero.in/public/awb-track-details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                awb: paramTrackAwb
            })
        })

        const result = await response.json()

        if (result.status === 'success') {
            setOrderDetails(result.data)
        }
        console.log('result od tracking is', result.data)
        console.log('tracking data ids jgjhkjhkjhijhkjh', result.data.tracking)
    }

    const getDateTime = (date) => {
        const dateFormat = new Date(date)
        const day = dateFormat.getDate()
        const month = dateFormat.getMonth() + 1
        const year = dateFormat.getFullYear()
        const time = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return `${day}/${month}/${year}, ${time} `
    }

    const getStatusColorClass = (status) => {
        switch (status) {
            case 'Delivered':
                return 'bg-green-600';
            case 'Manifested':
                return 'bg-blue-200';
            case 'Pending':
                return 'bg-red-400';
            case 'In Transit':
                return 'bg-blue-400';
            case 'Dispatched':
                return 'bg-blue-700';
            default:
                return 'bg-gray-500';
        }
    };

    return (
        <div>
            {orderDetails && (
                <div className='md:pt-28 pt-20  pb-10 flex md:flex-row flex-col  text-slate-600 bg-gray-50 md:px-20 px-4' >
                    <Helmet>
                        <title>Couriero: Track Shipment Details</title>
                        <meta name="description" content="View detailed information about your shipment with Couriero. Access order details, delivery status, and tracking information to monitor your parcel's journey." />
                        <link rel="canonical" href="/track-shipment/:trackAwb"/>
                    </Helmet>
                    <div className='flex  flex-col md:w-1/2 w-full mx-2'>
                        <div className='bg-white  rounded-2xl  my-2 flex flex-col py-2 border border-gray-300 shadow-md'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-orange-400 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-cart-arrow-down"></i>
                                </div>
                                <div>
                                    <h2 className='text-xl  font-semibold ml-2'>Order Details</h2>
                                </div>
                            </div>
                            <div className='flex flex-row items-start my-5'>
                                <div className='flex flex-col  px-5 w-1/2 border-r-2'>
                                    <div className='flex flex-col justify-start my-2'>
                                        <h2 className='font-bold text-sm underline'>AWB Number</h2>
                                        <h2 className='md:text-2xl lg:text-xl text-lg font-bold'>{orderDetails.awb}</h2>
                                    </div>
                                    <div className='flex flex-col justify-start my-2'>
                                        <h2 className='font-bold text-sm underline'>Order Id</h2>
                                        <h2 className='md:text-base text-sm font-semibold'>{orderDetails.orderId}</h2>
                                    </div>
                                    <div className='flex flex-col justify-start my-2'>
                                        <h2 className='font-bold text-sm underline'>Order Date</h2>
                                        <h2 className='text-base font-semibold'>{getDateTime(orderDetails.orderDate)}</h2>
                                    </div>
                                </div>
                                <div className='w-1/2 px-5 justify-end flex flex-col my-2'>
                                    <h2 className='font-bold text-sm underline'>Company Name</h2>
                                    <img src={orderDetails.logo} alt='companyLogo' className='w-44 h-8 my-4' />
                                    <h2 className='text-lg font-semibold'>{orderDetails.companyName}</h2>

                                </div>
                            </div>
                        </div>
                        <div className='bg-white rounded-2xl  my-2 flex flex-col py-2 border shadow-md border-gray-300'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-orange-400 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-check"></i>
                                </div>
                                <div>
                                    <h2 className='text-xl font-semibold ml-2'>Delivery Details</h2>
                                </div>
                            </div>
                            <div className='flex flex-row my-5 px-5'>
                                <div className='flex flex-col w-1/2 border-r-2'>
                                    <h2 className='font-bold text-sm underline'>Status</h2>
                                    <h2 className='font-bold text-2xl'>{orderDetails.shipmentStatus}</h2>
                                </div>
                                <div className='flex flex-col w-1/2 px-5'>
                                    <h2 className='font-bold text-sm underline'>{orderDetails.shipmentStatus === 'Delivered' ? 'Delivery Date' : 'Last Update Time'}</h2>
                                    <h2>{getDateTime(orderDetails.tracking[0].scanDate)}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='border border-gray-300 shadow-md rounded-2xl w-full md:w-1/2 bg-white mt-2  h-[29rem] flex flex-col py-4 mx-2'>
                        <div className='flex flex-row items-center border-b-2 mx-2 py-1'>
                            <div className="ml-3 w-8 h-8 rounded-full bg-orange-400 flex items-center justify-center text-white relatives z-10">
                                <i className="fa-solid fa-list-ul"></i>
                            </div>
                            <div>
                                <h3 className='text-base font-bold ml-2'> Tracking Details</h3>
                            </div>
                        </div>
                        <div className=" px-2 flex flex-col py-4 w-full overflow-auto">
                            {orderDetails.tracking.map((element) => {
                                return (
                                    <div className="flex py-3 flex-row" key={`${element.scanDate}`}>
                                        <div className='relative hidden md:block'>
                                            <div className=" h-12 w-10 absolute flex items-center justify-center">
                                                <div className="h-full w-1 bg-gray-200"></div>
                                            </div>
                                            <div className={`ml-3 w-4 h-4 rounded-full flex items-center justify-center  text-white absolute z-10 ${getStatusColorClass(element.scanType)}`}>
                                                <i className="fa-solid fa-arrow-right text-xs"></i>
                                            </div>
                                        </div>
                                        <div className="flex md:flex-row  md:ml-10 justify-items-start w-full">
                                            <div className='flex flex-col w-3/12'>
                                                <div className='flex flex-row space-x-1 whitespace-nowrap'>
                                                    <h2 className='text-xs font-black '> Activity:</h2>
                                                    <h2 className='text-xs font-semibold '>{element.scanType}</h2>
                                                </div>
                                                <h2 className='text-xs font-medium tracking-tighter	'>{getDateTime(element.scanDate)}</h2>
                                            </div>
                                            <h2 className='text-xs font-medium w-4/12 mx-2 pl-5 tracking-tighter flex-wrap'><strong>Location :- </strong>{element.scanLocation}</h2>
                                            <h2 className='text-xs font-bold w-5/12 mx-2 tracking-tighter  flex-wrap'>{element.scanDetail}</h2>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TrackShipmentDetails